<template>
  <div class="navbar-container d-flex content align-items-center">

    <!-- Nav Menu Toggler -->
    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link
          class="nav-link"
          @click="toggleVerticalMenuActive"
        >
          <feather-icon
            icon="MenuIcon"
            size="21"
          />
        </b-link>
      </li>
    </ul>

    <!-- Left Col -->
    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
      <dark-Toggler class="d-none d-lg-block" />
    </div>

    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown
        right
        toggle-class="d-flex align-items-center dropdown-user-link"
      >
        <template #button-content>
          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{ fullName }}
            </p>
            <span class="user-status">{{ userStatus }}</span>
          </div>
          <b-avatar
            size="40"
            variant="light-primary"
            badge

            class="badge-minimal"
            badge-variant="success"
          />
        </template>

        <!--  <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"
          />
          <span>Profile</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MailIcon"
            class="mr-50"
          />
          <span>Inbox</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="CheckSquareIcon"
            class="mr-50"
          />
          <span>Task</span>
        </b-dropdown-item>

        <b-dropdown-item link-class="d-flex align-items-center">
          <feather-icon
            size="16"
            icon="MessageSquareIcon"
            class="mr-50"
          />
          <span>Chat</span>
        </b-dropdown-item>

        <b-dropdown-divider />
 -->
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="logout"
        >
          <feather-icon
            size="26"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Logout</span>
        </b-dropdown-item>
        <b-dropdown-divider />
        <b-dropdown-item
          link-class="d-flex align-items-center"
          @click="Fulllogout"
        >
          <feather-icon
            size="26"
            icon="LogOutIcon"
            class="mr-50"
          />
          <span>Signin With Different User</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
import {
  BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
} from 'bootstrap-vue'
import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'

export default {
  components: {
    BLink,
    BNavbarNav,
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,

    // Navbar Components
    DarkToggler,
  },
  props: {
    toggleVerticalMenuActive: {
      type: Function,
      default: () => {},
    },
  },
  data() {
    return {
      userName: JSON.parse(localStorage.getItem('userData')).userName,
      fullName: JSON.parse(localStorage.getItem('userData')).fullName,
      userStatus: JSON.parse(localStorage.getItem('userData')).role,
      user: JSON.parse(localStorage.getItem('userData'))
    }
  },
  methods: {
    logout() {
    // console.log('I wase Clicked')
      this.$http
        .get(
          '/auth/logout',
        ).finally(() => {
          localStorage.clear()
          this.$router.replace('/login')
        })
    },
    Fulllogout() {
      this.$http
        .get(
          '/auth/logout',
        ).finally(() => {
          localStorage.clear()
          const { origin } = new URL(document.URL)
          location.href = `https://login.microsoftonline.com/common/oauth2/logout?post_logout_redirect_uri=${origin}/login?login=true`
        })
    },
  },
  created(){
      this.$http
        .get(
          '/auth/IsLogedIn',
        ).then(x=>{
          console.log(`islogedin status code ${x.status}`)
        })
   
  }
}
</script>
