export default [
  // {
  //   title: 'Home',
  //   route: 'home',
  //   icon: 'HomeIcon',
  // },
  {
    title: 'Export',
    route: 'export-report',
    icon: 'FileIcon',
  },
  {
    title: 'Email Doctor Setting',
    route: 'Email-Doctor-Settings',
    icon: 'FileIcon',
    action: 'read',
    resource: 'Admin',
  },
  // {
  //   title: 'Patients',
  //   route: 'Patients',
  //   icon: 'FileIcon',
  // },
  {
    title: 'Practices',
    route: 'Practices',
    icon: 'FileIcon',
    action: 'read',
    resource: 'Admin',
  },
  {
    title: 'Users',
    route: 'Users',
    icon: 'FileIcon',
    action: 'read',
    resource: 'Admin',
  },
  {
    title: 'Report Explorer',
    route: 'ReportExplorer',
    icon: 'FolderIcon',
    action: 'read',
    resource: 'Admin',
  }
]
